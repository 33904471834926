@font-face {
  font-family: "DMSans-Bold";
  src: url("../src/assets/fonts/DMSans-Bold.ttf");
}
html {
  scroll-behavior: smooth;
}

@font-face {
  font-family: "DMSans-Medium";
  src: url("../src/assets/fonts/DMSans-Medium.ttf");
}
@font-face {
  font-family: "DMSans-Regular";
  src: url("../src/assets/fonts/DMSans-Regular.ttf");
}
@font-face {
  font-family: "Inter-Bold";
  src: url("../src/assets/fonts/Inter-Bold.ttf");
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("../src/assets/fonts/Poppins-Bold.ttf");
}
@font-face {
  font-family: "Roboto-Regular";
  src: url("../src/assets/fonts/Roboto-Regular.ttf");
}
@font-face {
  font-family: "Roboto-Medium";
  src: url("../src/assets/fonts/Roboto-Medium.ttf");
}

.mainDiv {
  height: auto;
  /* margin-left: 150px; */
  width: 100%;
  position: relative;
  margin-top: -130px;
  background: linear-gradient(0deg, #fff 36.98%, rgba(255, 255, 255, 0));
}
.mainDiv2 {
  height: auto;
  width: 100%;
  position: relative;
  background-color: "none";
}
.horizontal-scroll-container {
  display: flex;
  overflow-x: hidden;
  white-space: nowrap;
  width: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.allcut .watch-slider .swiper-slide {
  width: 205px;
  height: 10px;
}
.scroll-item {
  flex: 0 0 auto;
  padding: 16px; /* Add your desired padding or styling here */
}
/* HorizontalScrollList.css */
/* .horizontal-scroll-container {
  width: 100%;
  overflow: hidden;
} */

.horizontal-slider-container {
  width: 100%;
  background-color: aquamarine;
  margin: 0 auto;
}

.item {
  flex: 0 0 auto;
  width: 200px; /* Set the width of each item as needed */
  scroll-snap-align: start;
  border: 1px solid #ccc; /* Optional: Add border or other styles */
  padding: 10px; /* Optional: Add padding or other styles */
}

/* Add these styles to your CSS file or component styles */
.card-container {
  width: 97%;
  /* border: 1px solid #000; */
  border-radius: 10px;
}

.select-color {
  border-radius: 10px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 1rem;
  /* box-shadow: 1px 1px 10px 1px wheat; */
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.4);
}
.unselect-color {
  margin-left: 1rem;
  margin-top: 1rem;
  border: 1px solid none;
}

.card {
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  /* cursor: pointer; */
  width: 97%;
  height: 200px;
  /* background-color: #fe0e78; */
  transition: transform 0.2s;
}

.cardMaths {
  border-radius: 10px;
  /* cursor: pointer; */
  text-align: center;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center horizontally */
  justify-content: center; /* Center vertically */
}

/* .card:hover {
  transform: scale(1.02);
} */

/* 
.card-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
} */

.article {
  /* height: 100%; */
  position: relative;
  overflow: hidden;
  margin-top: 10vh;
}
.footerText {
  font-size: 17px;
  font-family: "Inter-Bold";
  margin-left: 40px;
  /* cursor: pointer; */
}
.header {
  font-size: 80px;
  color: white;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: fit-content;
  margin: auto;
}

.grid-view {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Three columns */
  grid-gap: 20px;
}

.grid-item {
  padding: 10px;
  border: 0.2px solid #d9d9d9;
  border-radius: 3%;
  box-sizing: border-box;
}

.grid-item-selected {
  padding: 10px;
  /* border: 1px solid #d9d9d9; */
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.4);
  border-radius: 3%;
  box-sizing: border-box;
}

/* Define a class for the div that you want to add a background image to */
.background-iamge-div {
}

.bordered-div {
  justify-content: center;
  display: flex;
  align-items: center;
  margin-top: 30px;
  flex-direction: column;
  width: "auto"; /* Set the width of the div */
  height: auto; /* Set the height of the div */
  border-radius: 35px;
  border: 1px solid #d6d6d6; /* Add a 2px solid black border */
  background-color: #fff;
  padding: 10px; /* Add some padding inside the border */
}

.list-container {
  display: flex;
}

.account-form {
  display: flex;
  margin-top: 30px;
  flex-direction: column;
  align-items: center;
}

.account-field {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 15px;
  /* justify-content: space-between; */
  height: 25px;
  width: 320px;
  border: 1px solid #ccc;
  border-radius: 50px;
}

.account-field input {
  border: 0px solid #ccc;
}

/* CSS */
.account-field input:focus {
  outline: none;
}

.account-field:focus-within {
  border: 1px solid #fe0e78;
  transition: background-color 0.3s ease-in-out;
}

/* Add this CSS to your stylesheet */
.my-slider .slick-prev,
.my-slider .slick-next {
  display: none !important;
}

button {
  border-radius: 100px;
  text-transform: uppercase;
  background-color: #ca0b61;
  padding: 0 20px 4px;
  height: 30px;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #fff;
  text-shadow: -0.241186px 0.241186px 2.41186px rgba(27, 26, 64, 0.15);
  min-width: 50px;
  border: none;
  z-index: 1;
  position: relative;
}
button::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: -3.2px;
  left: 0;
  background-color: #fe0e78;
  box-shadow: -0.241186px 0.241186px 2.41186px #ffde6926;
  border-radius: 33.5248px;
  z-index: -1;
  transition: all 0.1s;
}
button:hover:before {
  top: 0;
}
